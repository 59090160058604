import React from "react";
import "./vsl-section11.styles.scss";
import { projects } from "../../../../components/projects/projects.component";
const VslSection11 = () => {
  return (
    <div className="section11-component">
      <div className="container">
        <div className="header">
          <h1>FIND YOUR MOJO!</h1>
          <div className="subtext">
            We will create a website that is visually appealing and user
            friendly, making it easier for customers to find you and interact
            with your business. Our developers use the latest coding languages
            (HTML, CSS, SaSS, Gulp, PHP, Node.us, WordPress) and Platforms
            (React) to ensure your website is fast, secure, and up-to-date.
          </div>
        </div>
        {/* <div className="row">
          {projects
            .filter((x) => x.selected)
            .map((project, i) => (
              <div className="col-lg-4 service-container" key={i}>
                <div className={`service-image ${project.className}`}>
                  {project.images.map((x, index) => (
                    <img
                      key={index}
                      className={
                        project.images.length > 1 ? "multiple" : "single"
                      }
                      src={x}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            ))}
        </div> */}
      </div>
    </div>
  );
};

export default VslSection11;
