import React from "react";

// import { ReactComponent as Icon } from "../../assets/home/button-arrow.svg";

import "./index.css";
import BackgroundEffect from "../../background-effect/background-effect.component";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../button-primary/button-primary.component";

const ReadyToStartProjectWithBg = () => {
  const navigate = useNavigate();
  return (
    <div className="ready-to-start-project-component small-banner-component">
      <BackgroundEffect classes="ready-to-start-bg-effect" />
      <div className="overlay-ready"></div>
      <div className="ready-to-start-project-boost container">
        <div className="ready-to-start-project__content-boost">
          <h2 className="ready-heading">Ready To Start Your Project?</h2>
          <div className="ready-btn-div-boost">
            <PrimaryButton onClick={() => navigate("/booking")}>
              <span className="button-text">Lets Discuss</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReadyToStartProjectWithBg;
