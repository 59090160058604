import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { storage2 } from "../../../firebase/fiebase2"; 

import { videos } from "../../revenue-growth/VideoGallery/videosData";

import arrow from "../../../assets/operations/arrow-next.svg";
import reading from "../../../assets/operations/reading.png";
import playbtn from "../../../assets/operations/playbtn.svg";
const Reading = () => {
  const [showAudioPlayer, setshowAudioPlayer] = useState(false);
  const navigate = useNavigate();
  const { title } = useParams();

  // Replace dashes with spaces to match the title in videos array
  const formattedTitle = title.replace(/-/g, " ");

  // Find the video that matches the title
  const Currentvideo = videos.find((video) => video.title === formattedTitle);
  // .......

  const [currentPodcastUrl, setCurrentPodcastUrl] = useState(null); // State to store the URL of the matched podcast

  // Fetch the matching podcast URL from Firebase storage
  useEffect(() => {
    const fetchPodcastUrl = async () => {
      try {
        // Create a reference to the 'podcasts' folder in Firebase Storage
        const podcastsRef = storage2.child("podcasts");
  
        // List all files in the 'podcasts' folder
        const listResult = await podcastsRef.listAll();
  
        // Loop through each file in the folder
        for (const itemRef of listResult.items) {
          const filename = itemRef.name; // Get the name of the podcast file
          
          // Check if the filename matches the `videoUrl` in `Currentvideo`
          if (filename === Currentvideo?.videoUrl) {
            // If a match is found, get the download URL for the podcast
            const url = await itemRef.getDownloadURL();
            // console.log("Matched Podcast Found:", { filename, url });

            // Set the matched podcast URL in state
            setCurrentPodcastUrl(url);
            break; // Stop the loop once the podcast is found
          }
        }
  
      } catch (error) {
        console.error("Error fetching podcast URL:", error);
      }
    };
  
    if (Currentvideo?.videoUrl) {
      fetchPodcastUrl();
    }
  
  }, [Currentvideo?.videoUrl]); 
  // .............
// console.log(Currentvideo,"Currentvideo")
  // Filter videos by the same category as the current video
  const filteredVideos = videos.filter(
    (video) => video.category === Currentvideo?.category
  );

  // console.log("Filtered Videos with same category:", filteredVideos);

  // Function to shuffle an array everytime to get random links
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
  };

  // Get the first 7 videos from the Shuffled videos array
  const firstSevenVideos = shuffleArray([...filteredVideos]).slice(0, 7);

  // Audio Player render Handler
  const handlePlayAudio = () => {
    setshowAudioPlayer(!showAudioPlayer);
  };

  // function for maiking bold words that are before : sign
  const renderDescription = (text) => {
    const [boldpart, rest] = text.split(":");
    return (
      <p>
        <strong style={{ fontWeight: "bold" }}>{boldpart}</strong>
        {rest}
      </p>
    );
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="reading-main">
          <div className="reading-parent ">
            <p className="reading-title-g flex-col">Mojo News</p>
            <img className="arr-mb" src={arrow} alt="" />
            {/* <p className="reading-title-g flex-col">Resources</p>
            <img className="arr-mb" src={arrow} alt="" />
            <p className="reading-title-g flex-col">Revenue Makers Podcast</p>
            <img className="arr-mb" src={arrow} alt="" /> */}
            <p className="reading-title flex-col">{Currentvideo.title}</p>
          </div>
          {/*   */}
          <div className="reading-parent2">
            {/* left */}
            <div className="reading-left flex-col">
              <div className="reading-heading-box">
                <div className="thumbail-and-text-ddiv">
                  <img
                    className="reading-img"
                    src={Currentvideo.thumbnail}
                    alt=""
                  />

                  <div className="reading-vid">
                    <img
                      className="play-btn"
                      src={playbtn}
                      alt=""
                      onClick={handlePlayAudio}
                    />
                    <div className="">
                      <p className="reading-heading">
                        {/* Reading Through The Revenue Operations Manual */}
                        {Currentvideo.title}
                      </p>
                      <p className="reading-time">19:14</p>
                    </div>
                  </div>
                </div>
                {showAudioPlayer && (
                  <AudioPlayer
                    className="audio-player"
                    autoPlay={true}
                    src={currentPodcastUrl}
                    // onPlay={e => console.log("onPlay")}
                  />
                )}
              </div>

              <p className="reading-title my-10">
                {/* Disrupting the market requires helping people overcome their
                fear of change. It’s a huge undertaking, especially in
                industries that don’t necessarily want to reinvent the wheel.
                Drawing on her own experience, Lauren Burkemeyer, CMO at YuLife,
                tells us what it’s like leading a challenger brand to success. */}
                {renderDescription(Currentvideo.description1)}
              </p>
              <p className="reading-title my-10">
                {/* Lauren defines what a challenger brand is, and how these
                companies disrupt established industries with unique strategies.
                She emphasizes the importance of fostering a culture that
                embraces creativity and risk-taking, and how leveraging
                storytelling and social proof can build credibility for your
                brand.{" "} */}
                {renderDescription(Currentvideo.description2)}
              </p>
              <p className="reading-title my-10">
                {/* Discover how to confidently position your brand against industry
                giants in this episode. */}
                {renderDescription(Currentvideo.description3)}
              </p>
              {/* <h2 className="reading-heading2 mt-10 mb-8">
                In this episode, you’ll learn:
              </h2>
              <p className="reading-title ">
                1. The definition of a challenger brand <br />
                2. How to build trust with customers through social proof <br />
                3. How to market your brand as an innovator in traditional
                industries
              </p>
              <h2 className="reading-heading2 mt-10 mb-8">
                Jump into the conversation:
              </h2>
              <p className="reading-title mb-18">
                <span className="reading-title-g"> 00:00</span> Introducing
                Lauren Berkemeyer <br />
                <span className="reading-title-g"> 03:20</span> What is a
                challenger brand? <br />
                <span className="reading-title-g"> 05:53</span> Building a
                culture of innovation and curiosity <br />
                <span className="reading-title-g"> 07:35</span> Balancing demand
                gen and brand building <br />
                <span className="reading-title-g"> 11:21</span> Establish
                credibility with social proof <br />
                <span className="reading-title-g"> 16:09</span> Is a challenger
                brand the right fit for you?
              </p> */}
            </div>
            {/*  right*/}
            <div className="reading-right flex-col">
              <div className="reading-right-box">
                <p className="reading-right-heading">Related Resources</p>
                {firstSevenVideos.map((video, index) => (
                  <div key={index}>
                    <p
                      className="reading-right-g"
                      onClick={() =>
                        navigate(`/${video.title.replace(/\s+/g, "-")}`)
                      }
                    >
                      {video.title}
                    </p>
                  </div>
                ))}
                {/* <p className="reading-right-g">Customer Relationships</p>
                <p className="reading-right-g">Management & Leadership</p>
                <p className="reading-right-g">Professional Development</p>
                <p className="reading-right-g">Prospecting & Qualifying</p>
                <p className="reading-right-g">Research Library</p>
                <p className="reading-right-g">Sales Process</p>
                <p className="reading-right-g">Self Development</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reading;
