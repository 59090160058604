import React from "react";
import "./OurProcess.css";
// import s from "../../../assets/boost/gold-circle.webp"

const OurProcessNew = ({
  step1Heading,
  step1descreption,
  step2Heading,
  step2descreption,
  step3Heading,
  step3descreption,
  step4Heading,
  step4descreption,
}) => {
  return (
    <div className="process-container">
      <div className="gold-circle desktop-gold-circle">
        <h1 className="circle-title">
          Our <br className="" /> Process
        </h1>
      </div>
      <div className="mobile-gold-circle">
      <div className="gold-circle ">
        <h1 className="circle-title">
          Our <br className="" /> Process
        </h1>
      </div>
      </div>
      <div className="steps-container">
        <div className="step">
          <div className="step-number">1</div>
          <div className="step-content">
            <h3>{step1Heading}</h3>
            <p>{step1descreption}</p>
          </div>
        </div>
        <div className="step2">
          <div className="step-number">2</div>
          <div className="step-content">
            <h3>{step2Heading}</h3>
            <p>{step2descreption}</p>
          </div>
        </div>
        <div className="step3">
          <div className="step-number">3</div>
          <div className="step-content">
            <h3>{step3Heading}</h3>
            <p>{step3descreption}</p>
          </div>
        </div>
        <div className="step4">
          <div className="step-number">4</div>
          <div className="step-content">
            <h3>{step4Heading}</h3>
            <p>{step4descreption}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcessNew;
