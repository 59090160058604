import React, { useState } from "react";
import "./canlook.css";

import VideoOne from "./VideoOne";
import VideoTwo from "./VideoTwo";
import VideoThree from "./VideoThree";

const CanLook = () => {
  return (
    <div className="can-look-main-div">
      <div>
        <h1 className="digitalheading why-choose-heading text-center">
          <span className="digitaltitle">See</span> What&apos;s Possible
        </h1>

        <div className="can-look-images-main-div">
          <VideoOne />

          <VideoTwo />

          <VideoThree />
        </div>
      </div>
    </div>
  );
};

export default CanLook;
