import React, { useEffect, useRef } from "react";
import "./DigitalDirector.css";

import digitalimg from "../../../assets/newpage/digitall.png";
import bggold from "../../../assets/newpage/digitalbg.png";
import bg from "../../../assets/newpage/Unparalleled.png";
import arrow from "../../../assets/newpage/arrow.svg";

const DigitalDirector = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playVideo = () => {
        // Check screen width
        video.play().catch((error) => {
          console.error("Error attempting to play the video:", error);
        });
      };

      // Play or pause video based on screen width
      playVideo();

      // Add event listener for window resize
      window.addEventListener("resize", playVideo);

      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener("resize", playVideo);
      };
    }
  }, []);
  return (
    <div
      className="d-flex justify-content-center position-relative overflow-hidden"
      style={{ background: "black", height: "100%" }}
    >
      <div className="digitalmain ">
        <img className="digital-bg" src={bggold} alt="" />
        {/* row1 */}
        <div className="TeamSlidermain pl-0" style={{ cursor: "auto" }}>
          <div className="team-content ">
            <h2 className="text-center mt-19 text-48 text-white">
              Crafting <span className="goldcolor ">Solutions</span> For Your
              Brand{" "}
            </h2>

            <div className="">
              <video
                ref={videoRef}
                className="cursor-pointer our-team-video"
                src="/ourteamvid.mp4"
                // poster="/images/home/youcan.png"
                width={1907.34}
                height={1182.23}
                loop
                playsInline
                controls
                muted
              ></video>
            </div>

            {/*  */}

            {/*  */}

            {/* <h1 className="team-heading text-center">
                A Collaborative Journey
              </h1> */}

            {/* <p className="team-para para mt-4 mb-4 text-center">
                At Innovative MOJO, we believe in collaboration and
                transparency. Our process involves working closely with our
                clients every step of the way.
                <br />
                <br />
                At the heart of every successful digital venture lies a team of
                skilled professionals, and at Innovative MOJO, we're proud to
                have a crew that makes the impossible, possible. It's their
                expertise, creativity, and forward-thinking strategies that fuel
                our promise and deliver results beyond expectations. Whether
                it's through the precision of our SEO experts, the artistic
                flair of our graphic designers, or the technical savvy of our
                developers, each member plays a pivotal role.
                <br /> <br />
                They're not just workers; they're architects, crafting a
                masterpiece tailored specifically to your brand's needs. Before
                we introduce you to these magicians behind the curtain,
                understand that it is their relentless dedication and passion
                for innovation that empowers your business to flourish in the
                digital age
              </p>

              <div className="d-flex justify-content-center">
                <img className="team-view" src={view} alt="" />
              </div> */}
          </div>
        </div>

        {/* row2 */}

        {/* bottom */}
        <div
          className="digital-bottom "
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img className="digital-bottom-bg mt-19" src={bg} alt="" />

          <div className="">
            <h1 className="digitalheading text-center mt-19">
              Turn Viewers Into Advocates
            </h1>
            <p
              className="digital-para-b text-center"
              style={{
                maxWidth: "800px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Our approach blends creative storytelling with strategic SEO,
              ensuring your message not only reaches but resonates, turning
              clicks into conversions.
            </p>

            <div className="d-flex justify-content-center gap-3 mr-3 button-relative">
              <a href="/booking">
                <button className="btncontact ml-2">
                  Lets Discuss
                  <img className="ml-2 button-arrow" src={arrow} alt="" />
                </button>
              </a>
            </div>
          </div>
        </div>
        {/* <Bottom/> */}
      </div>
    </div>
  );
};

export default DigitalDirector;
