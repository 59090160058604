

import avatar from "../../../assets/newpage/stars.png";
const testimonials = [
    {
      id: 0,
      name: "Tiffany Murray",
      position: "Innovative MOJO Became My Beacon Of Hope",
      review:
        "Innovative MOJO became my beacon of hope after a disastrous first attempt at getting my website done. Their professionalism, quality, and rapid response have not only restored my faith but truly made them the BEST!",
      avatar: avatar, // Example image path
    },
    {
      id: 1,
      name: "Alexi Knight",
      position: "Truly Rewarding Partner - Absolutely Recommend",
      review:
        "The team at Innovative MOJO possesses that rare blend of speed and quality that has made them a truly rewarding partner. Absolutely recommend!",
      avatar: avatar, // Example image path
    },
    {
      id: 2,
      name: "Daniel Ortiz",
      position: "Delivering Top-Notch Designs!",
      review:
        "Handling my high standards with grace and delivering top-notch designs – that’s what MOJO is all about. Looking forward to a long-lasting relationship!",
      avatar: avatar, // Example image path
    },
    {
      id: 3,
      name: "Mayra Halliday",
      position: "Earns Praise For Professionalism And High-End Feel",
      review:
        "They promised and over-delivered a website that consistently earns us praise for its professionalism and high-end feel!",
      avatar: avatar, // Example image path
    },
    // new
    {
      id: 4,
      name: "Sugein Herrera",
      position: "",
      review:
        "Amazing they are. My representative, Eric, was super knowledgeable and patient. He was very responsive, understood my business and what I was looking for, always offered great suggestions and feedback, and always willing to go above and beyond. Overall the service is amazing and I would definitely recommend them.",
      avatar: avatar, // Example image path
    },
    {
      id: 5,
      name: "Manuela Mora",
      position: "",
      review:
        "Very professional team and attentive to my exact vision. Not only did I receive what I asked for, the team actually went                      above & beyond my expectations with my website. When I had questions, they were there to guide me in the right direction. If your looking for a professional efficient team, you found it!",
      avatar: avatar, // Example image path
    },
    {
      id: 6,
      name: "Carlos Fortin",
      position: "",
      review:
        "As a new small business owner, I was hesitant to try MOJO, but after speaking with Santiago, I decided to give it a shot. Thanks to his professional and innovative ideas, my business has grown, and I've hired four new employees. Highly recommend!",
      avatar: avatar, // Example image path
    },
    {
      id: 7,
      name: "Jose Portillo",
      position: "",
      review:
        "Working with Innovative Mojo Website Design was a pleasure. I couldn’t be happier with the turnout. It was a very enjoyable experience working with the team throughout the project. They had answers for all the questions I threw their way and just made things happen. I cant recommend them enough!",
      avatar: avatar, // Example image path
    },
    {
      id: 8,
      name: "Rhonda",
      position: "",
      review:
        "Mojo has developed several websites for me and my clients and have exceeded my expectations and always delivered ahead of schedule.",
      avatar: avatar, // Example image path
    },
  ];

  export default testimonials;