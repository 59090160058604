import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import thmbmnail1890 from "../../../assets/newpage/thumbnails/thumnbnail1890s.png";
import thmbmnailtsu from "../../../assets/newpage/thumbnails/tsubanner.png";
import thmbmnaildavid from "../../../assets/newpage/thumbnails/davidthubnail.png";
import videotsu from "../../../assets/portfolio/Innovative.mp4";

import ReactPlayer from "react-player";
import video180 from "../../../assets/portfolio/1890.mp4";
import Videodavid from "../../../assets/portfolio/SELECTHEALING.mp4";

function Video4() {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);

  const handleClose2 = () => setShowModal2(false);
  const handleShow2 = () => setShowModal2(true);

  const handleClose3 = () => setShowModal3(false);
  const handleShow3 = () => setShowModal3(true);

  return (
    <>
      {/* Button 1 */}
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow1}
      >
        <img className="digital-product2  mt-2" src={thmbmnail1890} alt="thmbmnail1890" />
        <h1 className="thmbnail-logo-text">1890 <span style={{marginLeft:"10px"}}>Universities</span></h1>
      </button>
      {/* Modal 1 */}
      <Modal
        backdrop="static"
        show={showModal1}
        onHide={handleClose1}
        size="xl"
        centered
      >
        <Modal.Body>
          <ReactPlayer url={video180} controls width="100%" height="auto" />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose1}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      

      {/* Button 2 */}
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow2}
      >
        <img className="digital-product2  mt-2" src={thmbmnailtsu} alt="thmbmnailtsu" />
        <h1 className="thmbnail-logo-text2">Tennessee <span style={{marginLeft:"10px",marginRight:"10px"}}> State </span> Universitiy</h1>

      </button>
      {/* Modal 2 */}
      <Modal
        backdrop="static"
        show={showModal2}
        onHide={handleClose2}
        size="xl"
        centered
      >
         <Modal.Body>
          <ReactPlayer url={videotsu} controls width="100%" height="auto" />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose2}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Button 3 */}
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow3}
      >
        <img className="digital-product2  mt-2" src={thmbmnaildavid} alt="thmbmnaildavid" />
        <h1 className="thmbnail-logo-text2">David   <span style={{marginLeft:"10px",marginRight:"10px"}}> Starbuck </span> Smith</h1>

      </button>
      {/* Modal 3 */}
      <Modal
        backdrop="static"
        show={showModal3}
        onHide={handleClose3}
        size="xl"
        centered
      >
       <Modal.Body>
          <ReactPlayer url={Videodavid} controls width="100%" height="auto" />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose3}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Video4;
