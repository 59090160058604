import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Keyboard,
  Navigation,
  Scrollbar,
  Autoplay,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "./style.css";
import Card from "./Card";
import NextCard from "./NextCard";
import PreviousCard from "./PreviousCard";

function TestimonialSliderMob() {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const totalSlides = 9; // Replace this with the actual number of slides if dynamic

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      // Listen to the slide change event
      swiper.on("slideChange", () => {
        setActiveIndex(swiper.realIndex); // Directly update the active index
      });
    }
  }, []);

  const nextIndex = (activeIndex + 1) % totalSlides;
  const previousIndex = (activeIndex - 1 + totalSlides) % totalSlides;
  const [delayedPreviousIndex, setDelayedPreviousIndex] =
    useState(previousIndex);

  useEffect(() => {
    // Only update delayedPreviousIndex when previousIndex changes
    const timer = setTimeout(() => {
      setDelayedPreviousIndex(previousIndex);
    }, 0); // 2-second delay

    // Cleanup the timeout if the component unmounts or previousIndex changes
    return () => clearTimeout(timer);
  }, [previousIndex]);
  return (
    <div className="w-full h-full pb-custom">
      <div className="parent-div-testimonial-slide">
        <div
          className="test-parent-for-pagination"
          style={{ maxWidth: "1443px", width: "100%" }}
        >
          <Swiper
            ref={swiperRef}
            breakpoints={{
              768: { slidesPerView: 1 },
              1024: { slidesPerView: 2.5, spaceBetween: 40 },
              1180: { slidesPerView: 2.5, spaceBetween: 40 },
              1323: { slidesPerView: 3, spaceBetween: 40 },
            }}
            slidesPerView={1}
            speed={1500}
            slidesPerGroup={1}
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            pagination={{
              clickable: true, // Makes the bullets clickable
            }}
            modules={[Keyboard, Navigation, Scrollbar, Autoplay, Pagination]}
            className="mySwiper"
          >
            {[...Array(totalSlides)].map((_, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`card-slide-testimonial ${
                    activeIndex === index ? "active-testimonial" : ""
                  }`}
                >
                  {activeIndex === index ? (
                    <Card isActive activeIndex={activeIndex} />
                  ) : nextIndex === index ? (
                    <NextCard isNext nextIndex={nextIndex} />
                  ) : delayedPreviousIndex === index ? ( // Use delayedPreviousIndex for 2s delay effect
                    <PreviousCard
                      isPrevious
                      previousIndex={delayedPreviousIndex}
                    />
                  ) : (
                    <Card />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSliderMob;
