import React from 'react';

const WhychooseCards = ({ heading, headingSpan, description }) => {
  return (
    <div className="whychoose-card">
        <div className='whychoose-cards-content-div'>
  
      <h2 className="included-cards-heading and-rio">{heading} </h2>
      <p className='whychoose-para'>{description}</p>
      </div>
    </div>
  );
};

export default WhychooseCards;
