import React from "react";
import "./BoostVisibilityCards.css";

import BoostVisibilityCard from "./BoostVisibilityCard";

import longterm from "../../../assets/boost/boost1.svg";
import transport from "../../../assets/boost/boost2.svg";
import user from "../../../assets/boost/boost3.svg";

const BoostVisibilityCards = ({ cardsData }) => {
  return (
    <div className="BoostVisibilityCards-main-div">
      <div className="BoostVisibilityCards-child-div">
        {cardsData.map((card, index) => (
          <BoostVisibilityCard
            key={index}
            image={card.image}
            heading={card.heading}
            description={card.description}
          />
        ))}
      </div>
    </div>
  );
};

export default BoostVisibilityCards;
