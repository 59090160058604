import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Cards.css";

// import stars from "../../assets/stars.png";
import TestimonialSlider from "../TestimonialSlider";

import globebg from "../../../assets/newpage/testimonialslide/globebg.png";
import leftshadow from "../../../assets/newpage/testimonialslide/Group 22.png";
// import rightshadow from "../../../assets/newpage/testimonialslide/shadowr.png";

const Cards = () => {
  // hide on mobile screen

  // useEffect(() => {
  //   gsap.registerPlugin(ScrollTrigger);

  //   gsap.to(".box ", {
  //     scrollTrigger: {
  //       trigger: ".card-row-1",
  //       start: "top bottom-=200",
  //       end: "bottom top+=200",
  //       scrub: true,
  //       speed: 0.5, 
  //       lag: 1,
  //     },
  //     rotation: 0,
  //     marginRight: "40px",
  //   });
  //   gsap.to(".box2 ", {
  //     scrollTrigger: {
  //       trigger: ".card-row-1",
  //       start: "top bottom-=200",
  //       end: "bottom top+=200",
  //       scrub: true,
  //     },
  //     rotation: 0,
  //     marginLeft: "40px",
  //   });
  //   gsap.to(".box3 ", {
  //     scrollTrigger: {
  //       trigger: ".card-row-2",
  //       start: "top bottom-=200",
  //       end: "bottom top+=200",
  //       scrub: true,
  //     },
  //     rotation: 0,
  //     marginRight: "40px",
  //   });
  //   gsap.to(".box4 ", {
  //     scrollTrigger: {
  //       trigger: ".card-row-2",
  //       start: "top bottom-=200",
  //       end: "bottom top+=200",
  //       scrub: true,
  //     },
  //     rotation: 0,
  //     marginLeft: "40px",
  //   });
  // }, []);

  return (
    <div
      className=" d-flex justify-content-center overflow-hidden "
      // <div className=" d-flex justify-content-center overflow-hidden cards-desktop-main"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
    >
      <div className=" cards-main-new ">
        {/* <div className=" cards-mainn "> */}
        <h1 className="digitalheading text-center mt-5 pt-5">
          The MOJO Effect Isn’t Just A Transaction
        </h1>
        <h2 className="digitaltitle text-center mb-5 pb-2">
          It's A Transformation
        </h2>
        {/* <p className="para cards-para text-center mb-5">
          We deeply understand the digital canvas and bring you solutions that
          paint your brand in dimensions <br /> and hues unknown before. But
          don't just take our word for it:
        </p> */}
        <img src={globebg} alt="globebg" className="globebg-testimonial" />
        <img
          src={leftshadow}
          alt="leftshadow"
          className="leftshadow-testimonial"
        />
        <img
          src={leftshadow}
          alt="rightshadow"
          className="rightshadow-testimonial"
        />
        {/* <img
          src={leftshadow}
          alt="leftshadow"
          className="leftshadow-testimonial"
        /> */}
        
        <TestimonialSlider />
        {/* row1 */}
        {/* <div className="d-flex mt-5 pt-5 card-row-1">
          <div className="box d-flex">
            <div className="">
              <img className="stars" src={stars} alt="" />
              <h1 className="box-heading my-3 ">
                Innovative MOJO Became My Beacon Of Hope
              </h1>
              <p className="box-para para mb-4">
                “Innovative MOJO became my beacon of hope after a disastrous
                first attempt at getting my website done. Their professionalism,
                quality, and rapid response have not only restored my faith but
                truly made them the BEST!”
              </p>
              <h2 className="box-name">- Tiffany Murray</h2>
            </div>
          </div>
          <div className="box2 d-flex">
            <div className="">
              <img className="stars" src={stars} alt="" />
              <h1 className="box-heading my-3">
                Truly Rewarding Partner - Absolutely Recommend
              </h1>
              <p className="box-para para mb-4">
                “The team at Innovative MOJO possesses that rare blend of speed
                and quality that has made them a truly rewarding partner.
                Absolutely recommend!”
              </p>
              <h2 className="box-name">- Alexi Knight </h2>
            </div>
          </div>
        </div> */}

        {/* row2 */}
        {/* <div className="d-flex mt-5 card-row-2">
          <div className="box3 d-flex">
            <div className="">
              <img className="stars" src={stars} alt="" />
              <h1 className="box-heading my-3">
                Delivering Top-Notch Designs!
              </h1>
              <p className="box-para para mb-4">
                “Handling my high standards with grace and delivering top-notch
                designs – that’s what MOJO is all about. Looking forward to a
                long-lasting relationship!
              </p>
              <h2 className="box-name">- Daniel Ortiz </h2>
            </div>
          </div>
          <div className="box4 d-flex">
            <div className="">
              <img className="stars" src={stars} alt="" />
              <h1 className="box-heading my-3">
                Earns Praise For Professionalism And High-End Feel
              </h1>
              <p className="box-para para mb-4">
                “They promised and over-delivered a website that consistently
                earns us praise for its professionalism and high-end feel!
              </p>
              <h2 className="box-name">- Mayra Halliday</h2>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Cards;
