import React from "react";

import "./Seohero.css";

import Navbarr from "../../navbar/navbar.component";

import herosvg from "../../../assets/seo/heroHeading.svg";
import arrwo from "../../../assets/seo/Arrow.svg"
const SEOHero = () => {
  return (
    <>
      <Navbarr />
      <div className="seo-parent">
        <div className="seo-parent-child">
          <div className="herosvg">
        <img src={herosvg} alt="hero svg" className="" />
        </div>
        <h1 className="heading-hero text-center ">SEO Mastery</h1>
        <p className="seo-hero-para mob-px-5">
          Unlock the full potential of your online presence with our
          comprehensive SEO Mastery Package. <br className="br-hidden" /> This all-in-one solution is
          designed to propel your business to the top of search engine rankings, <br className="br-hidden" />
          driving organic traffic, and converting visitors into loyal customers.
          Here's what you can expect:
        </p>
        <div className="herosvg">
        <img src={arrwo} alt="" />
        </div>
        </div>
      </div>
    </>
  );
};

export default SEOHero;
