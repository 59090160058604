import React, { useState } from "react";
import tick from "../../../../assets/tick.png";
import thumbnail from "../../../../assets/thumbnail1.png";
import bulldog from "../../../../assets/portfolio/logos/bulldog-logo.svg";
import "./vsl-section2.styles.scss";
const VslSection2 = () => {
  const [video, setVideo] = useState(false);
  return (
    <div className="section2-component">
      <div className="container">
        <h1>
          <span>GO DAWGS!</span> <br />
          LEARN HOW WE SCALED THIS TAILGATE EXPERIENCE WITH THE BUSINESS BOOST
          BLUEPRINT!
        </h1>
        <br />
        <div className="section-content-container">
          <div className="logo-with-content">
            <a target="__blank" href="https://bulldog-estates.com/">
              <img src={bulldog} alt="" />
            </a>
            <div>
              <h2>
                <a target="__blank" href="https://bulldog-estates.com/">
                  BULLDOG-ESTATES.COM
                </a>
              </h2>
              In the world of college football tailgating, timing is everything.
              Especially for the back-to-back National Champions Georgia
              Bulldogs! Our client, Bulldog Estates, a company specializing in
              tailgating lot sales, understood this all too well. They had a
              fantastic innovative idea, a loyal customer base, but they faced
              challenges in maximizing their business potential. That’s where
              our Business Boost Blueprint stepped in to revamp their operations
              and drive incredible results. <br /> <br />
              <a target="__blank" href="https://bulldog-estates.com/">
                Bulldogs-estates.com
              </a>
               specializes in selling tailgating lots. They originally had a
              website, but it failed to drive any meaningful traffic or meet
              their specific needs. That's when they decided to approach
              Innovative Mojo for a full-scale digital transformation.
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <h2>THE PROCESS</h2>
          <div className="process-description">
            We began by streamlining their entire business process, from
            customer inquiries to lot reservations and event logistics. Our
            Blueprint identified inefficiencies and bottlenecks, resulting in a
            significant increase in the speed of website visits and customer
            service. This meant more fans could have access to purchase their
            coveted tailgating lots promptly!
            <br /> <br />
            To reach a wider audience and improve their online presence, we
            implemented our proven SEO strategies. By optimizing their website
            with targeted keywords, quality content, and backlink building, we
            catapulted their website to the top ranks of search engines. The
            result? An impressive increase in organic website traffic and an
            influx of new tailgating enthusiasts. GO DAWGS! 
            <br /> <br />
            Our tailored website management solutions gave the client clarity.
            With a super clean interface, it created an easy user-friendly
            experience for visitors. We were able to reduce time spent managing
            the website. This freed up valuable hours for them to focus on
            enhancing their customer experience and exploring new business
            opportunities.
            <br /> <br />
            In the world of college football tailgating, the lot is the limit,
            which means it can be challenging to expand. Our Business Boost
            Blueprint incorporated digital solutions. This, in turn, enabled the
            client to scale their business creatively and tap into previously
            unexplored markets, Tailgating Estates. 
            <br /> <br />
            The Business Boost Blueprint wasn’t just a strategy; it was a
            game-changer. With streamlined operations, enhanced online
            visibility, efficient website management, and digital solutions for
            scaling, our client achieved remarkable success. They generated 500k
            in revenue within 2 months! Today, they are among the leaders in the
            college football tailgating industry, thanks to the power of the
            Business Boost Blueprint.
            <br /> <br />
            We built a brand-new website from scratch. Our team started with
            market research and user experience design. We then developed an
            interactive map feature using React to display available lots,
            integrated a digital contract-signing mechanism, and incorporated a
            secure payment gateway.
            <div className="process-pointers">
              <div className="process">
                <img src={tick} alt="" />
                <h3>Market Research:</h3>
                Conducted comprehensive market research to identify client needs
                and market gaps.
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <h3>Development Phase:</h3>
                Utilized React for developing an interactive map to display
                available lots.
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <h3>Contract Signing:</h3>
                Integrated a digital contract-signing mechanism to streamline
                the booking process.
              </div>
              <div className="process">
                <img src={tick} alt="" />
                <h3>Payment Gateway:</h3>
                Incorporated a secure payment system to facilitate smooth
                transactions.
              </div>
            </div>
          </div>
        </div>
        <div className="section-content-container">
          <div className="results-container">
            <div className="result-description">
              <h2>THE RESULTS</h2>
              Post-launch, the new website saw immediate results. In just two
              months,&nbsp;
              <a target="__blank" href="https://bulldog-estates.com/">
                Bulldogs-estates.com
              </a>
               generated over $500k in revenue and saw significant increases in
              user engagement and conversion rates.
              <div className="result-stats">
                <h4>
                  Revenue: <span>$500k in 2 months</span>
                </h4>
                <h4>
                  Revenue in 2 years: <span>1.76M</span>
                </h4>
                <h4>
                  User Engagement: <span> Increased by 60%</span>
                </h4>
                <h4>
                  Conversion Rate: <span> Up by 25%</span>
                </h4>
                <h4>
                  <span>Sold 22 lots</span>
                </h4>
              </div>
            </div>
            <div className="video-thumbnail">
              {video ? (
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/2MRCyULDjPQ?si=mO5dfpqyu998vwHd&amp;controls=0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              ) : (
                <img onClick={() => setVideo(true)} src={thumbnail} alt="" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VslSection2;
