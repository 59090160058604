import { useState } from "react";
import Modal from "react-bootstrap/Modal";

// import canon from "../../assets/thumbnails/canonnewThumbnail (2).png";
import canon from "../../../assets/newpage/thumbnails/canonnewThumbnail (2).png";
import canonlogo from "../../../assets/newpage/thumbnails/Canon.png";
import ReactPlayer from 'react-player';

function Product1() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button style={{background:"transparent", border:"none", outline:'none',}} onClick={handleShow}>
<div className="d-flex justify-content-center">
<img className="digital-product" src={canon} alt="" />
  
  </div> 
       <img className="digital-logo " src={canonlogo} alt="" />
      </button>

      
      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        {/* <h1>SCHEDULE YOUR CALL BELOW</h1> */}
        <Modal.Body >
      

{/* <iframe
         style={{width:"100%", height:"100%", minHeight:"70vh", border: 'none', borderRadius: '10px'}}
            src="https://www.youtube.com/embed/OEwePQduI_A?si=Idup2Ybm7d-jnw_i"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe> */}
             <ReactPlayer
        url="/_Far From Home_ - Shot on the Canon EOS R5 C (1).mp4"
        controls
        width="100%"
        height="auto"
      />
        </Modal.Body>
        <Modal.Footer>
          <button className="btncontact" variant="secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default Product1;
