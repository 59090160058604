import React from "react";

import "./card.css";

import { useParams } from "react-router-dom";

import pageContent from "./pagesContent";

import BoostVisibilityHero from "../../components/boost-visibility/Hero";
import BoostCards from "../../components/boost-visibility/BoostCards";
import BoostVisibilityCards from "../../components/boost-visibility/BoostVisibilityCards";
import ResuableCard from "../../components/boost-visibility/BoostCards/ReuseableCard";
import OurProcessNew from "../../components/boost-visibility/OurProcessNew";
import WhyInnovativeMojo from "../../components/boost-visibility/WhyUS";
// import Footer from "../../components/home-footer/home-footer.component";
import ReadyToStartProjectWithBg from "../../components/boost-visibility/Ready-to-Start";
import Footer from "../../components/footer/footer.component";
import BoostProcess from "../../components/boost-visibility/OUrProcess";

import longterm from "../../assets/boost/long-term.svg";
import transport from "../../assets/boost//transport.svg";
import user from "../../assets/boost/user-centric.svg";

const BoostVisibilityPage = () => {
  const { slug } = useParams();

  const content = pageContent[slug];

  // console.log("slug", slug);
  return (
    <>
      <BoostVisibilityHero
        heading={content.hero.heading}
        description={content.hero.description}
        image={content.hero.image}
      />
      <BoostVisibilityCards cardsData={content.cards} />
      {/* <div className='boostcards-main-div'>
    <div className='boost-cards'>
    <ResuableCard
      icon={longterm}
      title="Long-Term Results"
      description="We build SEO strategies designed to deliver steady, cumulative gains rather than temporary spikes."
    />
    <ResuableCard
      icon={user}
      title="User-Centric Focus"
      description="Our optimizations prioritize user experience, ensuring your site is fast, mobile-friendly, and easy to navigate."
    />
    <ResuableCard
      icon={transport}
      title="Transparent Reporting"
      description="Gain full visibility into your ranking progress, organic traffic growth, and keyword performance."
    />
    </div>
    </div> */}
      <OurProcessNew
        step1Heading={content.process.step1Heading}
        step1descreption={content.process.step1descreption}
        step2Heading={content.process.step2Heading}
        step2descreption={content.process.step2descreption}
        step3Heading={content.process.step3Heading}
        step3descreption={content.process.step3descreption}
        step4Heading={content.process.step4Heading}
        step4descreption={content.process.step4descreption}
      />
      {/* <BoostProcess/> */}
      <WhyInnovativeMojo
        image={content.why.image}
        listItems={content.why.listItems}
        heading={content.why.heading}
      />
      <ReadyToStartProjectWithBg />
      <Footer />
    </>
  );
};

export default BoostVisibilityPage;
