import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./style.css";


const CookieConsent = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Check if the cookie consent or rejection has already been handled
  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      setShowPopup(true); // Show popup if no consent or rejection cookie found
    }
  }, []);

  const handleAccept = () => {
    // Set a cookie for consent
    Cookies.set("cookieConsent", "true", { expires: 365 }); // Cookie lasts for a year
    setShowPopup(false); // Hide the popup
  };

  const handleReject = () => {
    // Set a cookie for rejection
    Cookies.set("cookieConsent", "rejected", { expires: 365 }); // Cookie lasts for a year
    setShowPopup(false); // Hide the popup
    // Optionally, add any logic to disable tracking or other features here
  };

  if (!showPopup) {
    return null; // Don't show anything if consent or rejection has been handled
  }

  return (
    <div style={popupStyle}>
      <p className="cookies-para">
        We use cookies to provide you with the best online experience. If you
        continue browsing, we consider that you accept our Cookie Policy and
        also agree to the terms of our 
        <a  style={{ marginLeft: "4px", color:"black" }} href="/privacy-policy">
        Privacy Policy
        </a>{" "}
        and {" "}
        <a style={{ color:"black" }} href="/terms-and-conditions">Terms of Use</a>.
      </p>
      <div className="buttonContainerStyle" style={buttonContainerStyle}>
        <button onClick={handleAccept} className="btncontact">
          Accept
        </button>
        <button onClick={handleReject} className="btncontact">
          Reject
        </button>
      </div>
    </div>
  );
};

const popupStyle = {
  position: "fixed",
  bottom: "10px",
  left: "10px",
  padding: "20px",
  backgroundColor: "#f1f1f1",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  zIndex: 1000,
  maxWidth: "900px",
};

const buttonContainerStyle = {
  display: "flex",
  gap: "20px",
};

export default CookieConsent;
