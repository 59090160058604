import React from "react";
import "./style.css";

import dots from "../../../assets/newpage/testimonialslide/clarity_block-quote-line.png";
import nextslidebg from "../../../assets/newpage/testimonialslide/Nextcardbg.png";
import avatar from "../../../assets/newpage/testimonialslide/avatar1.png";
import stars from "../../../assets/newpage/stars.png";

// Testimonial data (including images)
import testimonials from "./testimonialsData"; // Importing the testimonials data

const NextCard = ({ isNext, nextIndex }) => {
  // Find the testimonial that matches the given nextIndex
  const testimonial = testimonials.find((item) => item.id === nextIndex);
  return (
    <>
      {/* Next slide */}
      <div className={`Card-next-slide ${isNext ? "active-testimonial-next" : ""}`}>
        {/* Background image */}
        <img className="testimonial-b1-slide-next" src={nextslidebg} alt="light" />

        {/* Avatar image */}
        <div
          style={{
          
            paddingTop: "70px",
          }}
        >
          {/* <img className="testimonial-avatar-slide-next" src={avatar} alt="avatar" /> */}
          <p className="testimonial-text-center-name-next mt-4 mb-0">{testimonial?.name}</p>

        </div>
        {/* Avatar image */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-10px",
            // paddingTop: "25px",
            paddingBottom: "10px",
          }}
        >
          {/* <img className="testimonial-avatar-slide-next" src={avatar} alt="avatar" /> */}
          <img className="testimonial-star-slide-next" src={stars} alt="avatar" />
        </div>

        {/* Testimonial name */}

        {/* Testimonial position */}
        <p className="testimonial-text-center testimonial-postion-next mb-0"
         style={{
          padding: "0px 70px",
        }}
        >{testimonial?.position}</p>

        {/* Dots separator */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img className="testimonial-dots-slide-next" src={dots} alt="dots" />
        </div>

        {/* Testimonial review */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <p className="testimonial-text-review-next">{testimonial?.review}</p>
        </div>
      </div>
    </>
  );
};

export default NextCard;
