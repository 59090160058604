import React from "react";
import "./Unparalleled.css";

// import bg from "../../assets/Unparalleled.png";
import bg from "../../../assets//newpage/Unparalleled.png";
import Button from "../ui/Button";

const Unparalleled = () => {
  return (
    <div className="Unparalleled-main overflow-hidden"
  >
      <img className="Unparalleled-bg" src={bg} alt="" />
      <div className="Unparalleled-content mt-5"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <h1 className="text-center digitalheading">
          Dive Into Unparalleled Digital Transformation With Innovative MOJO
        </h1>
        <p className="text-center para my-3">
          where your story is told, your brand is bold, and your future unfolds.
          Let’s craft a digital legacy together that stands the test of time
          and trend. Contact us today.
        </p>
        <div className="d-flex justify-content-center">
          <Button text="Lets Discuss" />
        </div>
      </div>
    </div>
  );
};

export default Unparalleled;
