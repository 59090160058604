import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import healing from "../../../assets/canlook/healing.webp";
import healingVideo from "../../../assets/canlook/healing.mp4";
import ReactPlayer from "react-player";

function VideoOne() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow}
      >
        <div className="d-flex justify-content-center">
          <div className="thumbnail">
            <img className="digital-product" src={healing} alt="" />
            <div className="play-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
              >
                <polygon points="15,10 15,40 35,25" fill="white" />
              </svg>
            </div>
          </div>
        </div>

        <p className="can-look-image-title team-title">Select Healing</p>
      </button>

      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <Modal.Body>
          <ReactPlayer url={healingVideo} controls width="100%" height="auto" />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VideoOne;
