import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import healingVideo from "../../../assets/portfolio/Innovative.mp4";

function VideoThree({ customClass }) {
  const [show, setShow] = useState(true); // Start with the modal open

  const handleClose = () => setShow(false);

  return (
    <div className={`video-overlay ${customClass}`} style={{ display: show ? 'flex' : 'none' }}>
      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        <Modal.Body>
          <ReactPlayer url={healingVideo} playing controls width="100%" height="50vh" />
        </Modal.Body>
        <Modal.Footer>
          <button className="btncontact" variant="secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <style jsx>{`
        .video-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
      `}</style>
    </div>
  );
}

export default VideoThree;
