import React from "react";

import "./BoostVisibilityCards.css";

const BoostVisibilityCard = ({ image, heading, description }) => {
  return (
    <div className="Boost-cards-parent">
      <img
        src={image.default || image}
        alt={heading}
        className="boost-crd-img"
      />

      <div className="boost-content-div">
        <h2 className="Boost-cards-heading">{heading}</h2>
        {/* <ul className="boost-card-description">
        {description.map((point, index) => (
          <li key={index} className="boost-card-description-item">
            {point}
          </li>
        ))}
      </ul> */}
        <p className="boost-card-description">{description}</p>
      </div>
      <div className="boost-card-arrow-div">
        <svg
          className="boost-arrow-svg"
          width="24"
          height="17"
          viewBox="0 0 24 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.556 9.03042C23.9806 8.60588 23.9806 7.91756 23.556 7.49302L16.6377 0.574704C16.2132 0.150162 15.5248 0.150162 15.1003 0.574704C14.6758 0.999246 14.6758 1.68756 15.1003 2.11211L21.2499 8.26172L15.1003 14.4113C14.6758 14.8359 14.6758 15.5242 15.1003 15.9487C15.5248 16.3733 16.2132 16.3733 16.6377 15.9487L23.556 9.03042ZM0.263794 9.34883H22.7873V7.17461H0.263794V9.34883Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    // </div>
  );
};

export default BoostVisibilityCard;
