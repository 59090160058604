import React from 'react';

const IncludedCards = ({ image, heading, listItems }) => {
  return (
    <div className="included-cards-parent">
        <div className='included-cards-child'>
          <div className="included-cards-image-div" >
      {image && <img src={image} alt={heading} className="included-cards__image" />}
      </div>
      <h2 className="included-cards-heading">{heading}</h2>
      <ul className="included-cards-list">
        {listItems.map((item, index) => (
          <li key={index} className="included-cards-list-item">
            {item}
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default IncludedCards;
