import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/home/logo.svg";
import arrow from "../../assets/arrow.png";

import "./booking.styles.scss";
import VslSection15 from "../vsl/vsl-sections/vsl-section15/vsl-section15.component";
const Booking = () => {
  const scrollToNextSection = () => {
    const scrollHeight = window.innerHeight;
    window.scrollTo(0, scrollHeight);
  };
  const navigate = useNavigate();
  return (
    <div className="booking-component">
      <div className="booking-container">
        <div className=" container">
          <img
            src={logo}
            alt=""
            className="logo"
            onClick={() => navigate("/")}
          />
          <div className="subtext">IT’S TIME TO STOP PLAYING SMALL</div>
          <h1>LET’S GET YOUR BRAND SEEN! </h1>
          <div className="subtext1">
            BOOK YOUR CALL BELOW FOR A FREE CONSULTATION 
          </div>
          <img
            className="scroll-down"
            src={arrow}
            alt=""
            onClick={scrollToNextSection}
          />
          <div className="iframe-container">
            {/* <iframe
              title="iframe"
              src="https://www.cnvrsnly.com/widget/booking/eN7KCb3hQEiBMOwdsHxN"
            ></iframe> */}
            {/* <iframe
              src="https://www.cnvrsnly.com/widget/booking/OpnkE1qxeFR7a6j4sEfB"
              style={{
                width: "100%",
                height: "100%",
                minHeight: "700px",
                border: "none",
                overflow: "hidden",
              }}
              scrolling="no"
              id="ZRiHYlv8tkHyGWxJUaXQ_1712351353153"
            ></iframe> */}
            <iframe
              src="https://www.cnvrsnly.com/widget/booking/mhzIEiGm3YTuWTv7gj6O"
              className="booking-iframe"
              style={{
                width: "100%",
                height: "100%",
                // minHeight: "1030px",
                border: "none",
                // overflow: "hidden",
              }}
              scrolling="no"
              id="ZRiHYlv8tkHyGWxJUaXQ_1713985892306"
            ></iframe>
          </div>
        </div>
      </div>
      <VslSection15 />
    </div>
  );
};

export default Booking;
