import React from "react";

// import "./whychoose.css";

import WhychooseCards from "../../seoPage/WhyChoose/WhychooseCards";

const FusionWhyChoose = () => {
  return (
    <div className="whychoose-main">
      <div>
        <h1 className="digitalheading why-choose-heading text-center">
          Why Choose Our{" "}
          <span className="digitaltitle">SEO Mastery Package?</span>
        </h1>
        <div className="ChooseCards-main-div funsion-ChooseCards-main-div">
            <div className="why-choose-content-div">
          <WhychooseCards
            heading="Complete Digital Solution"
            description="Combines videography, SEO, and web design into one powerful package, covering all your digital marketing needs."
          />
          <WhychooseCards
            heading="Boost Your Online Presence"
            description="Gain a compelling video, a strategic SEO plan, and a stunning website that works together to elevate your brand."
          />
          <WhychooseCards
            heading="Consistent and Cohesive Branding"
            description="Ensure a unified brand message across all platforms, with a seamless fusion of content and design."
          />
          <WhychooseCards
            heading="Maximize Impact and ROI" 
            description="Crafted to deliver measurable results, from increased traffic and engagement to higher conversion rates."
          />
            <WhychooseCards
            heading="Expert Guidance"
            description="Our team of specialists handles every aspect, providing a smooth experience and a polished final product"
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FusionWhyChoose;
