import React from "react";

import "./hero.css";

import Navbarr from "../../navbar/navbar.component";
import hero from "../../../assets/boost/hero.png";

const BoostVisibilityHero = ({ heading, description, image }) => {
  return (
    <>
      <Navbarr />
      <div className="page-container" style={{ position: "relative" }}>
        <div className="overlayheroboosthead"></div>
        {/* Background Images */}
        <div className="bg-left" />
        {/* <div className="bg-right" /> */}
        <div className="overlay-div"></div>

        {/* Content Section */}
        <div className="content">
          <h1 className="boost-heading">{heading}</h1>
          {/* <div className="image-container">
            <img src={image} alt="Hero Graphic" className="hero-image" />
          </div> */}
          <p className="boost-hero-para">{description}</p>
        </div>
      </div>
    </>
  );
};

export default BoostVisibilityHero;
