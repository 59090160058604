import React from "react";
import { useParams } from "react-router-dom";

// import "./Seohero.css";

import Navbarr from "../../navbar/navbar.component";
import { videos } from "../../revenue-growth/VideoGallery/videosData";

import icon1 from "../../../assets/operations/icon1.svg";
import icon2 from "../../../assets/operations/spotify.svg";
import icon3 from "../../../assets/operations/youtube.svg";
import icon4 from "../../../assets/operations/icon4.svg";
import icon5 from "../../../assets/operations/icon5.svg";

const Hero = () => {
  const { title } = useParams(); // Get the title from URL

  // Replace dashes with spaces to match the title in videos array
  const formattedTitle = title.replace(/-/g, " ");

  // Find the video that matches the title
  const video = videos.find((video) => video.title === formattedTitle);
  // console.log("id on operations component",id);
  return (
    <>
      <Navbarr />
      <div className="operation-parent">
        <div className="operation-parent-child">
          <div className="operation-box">
            <div className="operation-content">
              <div className="operation-title-parent">
                <p className="operation-titles">PODCAST</p>
                <p className="operation-titles">OPERATIONS</p>
              </div>

              <h1 className="operation-heading">{video.title}</h1>
              <p className="operation-para">October 10, 2024 
                {/* The 6sense Team */}
                </p>
              {/* <p className="operation-para-bold">
                Listen to Revenue Makers wherever you listen to podcasts:
              </p>
              <div className="flex operation-icons">
                <img className="operation-icon" src={icon1} alt="" />
                <img className="operation-icon" src={icon2} alt="" />
                <img className="operation-icon" src={icon3} alt="" />
                <img className="operation-icon" src={icon4} alt="" />
                <img className="operation-icon" src={icon5} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
