import React from "react";
import "./style.css";

import b1 from "../../../assets/newpage/testimonialslide/Vectorb1.png";
import b2 from "../../../assets/newpage/testimonialslide/Vector2.png";
import avatar from "../../../assets/newpage/testimonialslide/avatar1.png";
import dots from "../../../assets/newpage/testimonialslide/clarity_block-quote-line.png";
import leftshadow from "../../../assets/newpage/testimonialslide/Group 22.png";
import stars from "../../../assets/newpage/stars.png";

import previouscardbg from "../../../assets/newpage/testimonialslide/previouscardbg.png";
import testimonials from "./testimonialsData";

const PreviousCard = ({ isPrevious, previousIndex }) => {
  const testimonial = testimonials.find((item) => item.id === previousIndex);
  console.log(previousIndex, "previousIndex");
  return (
    <>
      {/* Next slide ------ */}
       
      <div
        className={`Card-prev-slide  ${
          isPrevious ? "active-testimonial-next " : ""
        }`}
        style={{
          position: isPrevious ? "relative" : "absolute",
          zIndex: isPrevious ? 0 : "auto", // Apply z-index 0 if isPrevious is true
        }}
      >
        {/* <div  className={` card-slide-testimonial  ${isActive ? "active-testimonial" : ""}`}> */}
        <img
          className="testimonial-b1-slide-prev"
          src={previouscardbg}
          alt="light"
        />
        {/* <div
          className=""
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-40px",
          }}
        >
          <img
            className="testimonial-avatar-slide-next"
            src={avatar}
            alt="avatar"
          />
        </div>
        <p className="testimonial-text-center-name-next mt-4 mb-2">
          {testimonial?.name}
        </p>
        <p className="testimonial-text-center testimonial-postion-next  mb-0">
          {testimonial?.position}
        </p> */}

<div
          style={{
          
            paddingTop: "70px",
          }}
        >
          {/* <img className="testimonial-avatar-slide-next" src={avatar} alt="avatar" /> */}
          <p className="testimonial-text-center-name-next mt-4 mb-0">{testimonial?.name}</p>

        </div>
        {/* Avatar image */}
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-10px",
            // paddingTop: "25px",
            paddingBottom: "10px",
          }}
        >
          {/* <img className="testimonial-avatar-slide-next" src={avatar} alt="avatar" /> */}
          <img className="testimonial-star-slide-next" src={stars} alt="avatar" />
        </div>

        {/* Testimonial name */}

        {/* Testimonial position */}
        <p className="testimonial-text-center testimonial-postion-next mb-0"
        style={{
          padding: "0px 70px",
        }}
        >{testimonial?.position}</p>

        <div
          className=""
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img className="testimonial-dots-slide-next" src={dots} alt="dots" />
        </div>
        <div
          className=""
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <p className="testimonial-text-review-next">{testimonial?.review}</p>
        </div>
      </div>
    </>
  );
};

export default PreviousCard;
