import React from "react";
import "./style.css";

import b1 from "../../../assets/newpage/testimonialslide/Vectorb1.png";
import b2 from "../../../assets/newpage/testimonialslide/activebgnew.png";
import avatar from "../../../assets/newpage/stars.png";
import dots from "../../../assets/newpage/testimonialslide/clarity_block-quote-line.png";
import stars from "../../../assets/newpage/stars.png";

import nextslidebg from "../../../assets/newpage/testimonialslide/Nextcardbg.png";
import testimonials from "./testimonialsData"; // Importing the testimonials data


const Card = ({ isActive, activeIndex }) => {
  const testimonial = testimonials.find((item) => item.id === activeIndex);

  return (
    <>
      <img className="testimonial-b1-slide" src={b1} alt="light" />
      <img className="testimonial-b1-slide-2" src={b2} alt="dark" />
      {/* <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "-40px",
          paddingTop: "50px",
        }}
      >
        <img className="testimonial-stars-slide" src={avatar} alt="avatar" />
      </div> */}
      <p
        className="testimonial-text-center-name mt-3 mb-3"
        data-aos="zoom-in"
        data-aos-delay="500"
        data-aos-duration="800"
        style={{
          
          paddingTop: "70px",
        }}
      >
        {testimonial?.name}
      </p>
      <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "-40px",
          paddingTop: "25px",
          paddingBottom: "10px",
        }}
      >
        {/* <img className="testimonial-avatar-slide" src={avatar} alt="avatar" /> */}
        <img className="testimonial-stars-slide" src={avatar} alt="avatar" />
      </div>
      <p
        className="testimonial-text-center testimonial-postion-active mb-0"
        data-aos="zoom-in"
        data-aos-delay="500"
        data-aos-duration="800"
        style={{padding:"0px 90px",}}
      >
        {testimonial?.position}
      </p>
      <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img className="testimonial-dots-slide" src={dots} alt="dots" />
      </div>
      <div
        className=""
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <p
          className="testimonial-text-review"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1200"
          style={{marginLeft:"15px"}}
        >
          {testimonial?.review}
        </p>
      </div>
    </>
  );
};

export default Card;
