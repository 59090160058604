import React from "react";
import "./SEOSection.css";

import rightimg from "../../../assets/boost/right.png";

const WhyInnovativeMojo = ({ heading, image, listItems }) => {
  return (
    <div className="boost-main-div">
      <div className="boost-whyus-container">
        <div className="image-section">
          <img src={image} alt="SEO Section" className="seo-section-img" />
        </div>
        <div className="content-section">
          <h2>{heading}</h2>
          <ul className="boost-why-innovative-li">
            {listItems.map((item, index) => (
              <li key={index} className="boost-why-innovative-li-content">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WhyInnovativeMojo;
