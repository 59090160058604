import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import director from "../../../assets/newpage/thumbnails/intelnewThumbnail 3.png";
import directorogo from "../../../assets/newpage/thumbnails/midnewlogo.png";

import ReactPlayer from "react-player";

function Video3() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow}
      >
        <img className="digital-product mt-2" src={director} alt="" />
        <img className="digital-logo2 mt-2" src={directorogo} alt="" />
      </button>

      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        {/* <h1>SCHEDULE YOUR CALL BELOW</h1> */}
        <Modal.Body>
          {/* <iframe
            style={{
              width: "100%",
              height: "100%",
              minHeight: "70vh",
              border: "none",
              borderRadius: "10px",
            }}
            src="https://www.youtube.com/embed/esK3AmRkMtQ?si=yz5JPk3Qt_nZ8r5P"

            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe> */}

          <ReactPlayer
            url="/Meet Your Director.mp4"
            controls
            width="100%"
            height="auto"
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Video3;
