import seohero from "../../assets/boost/hero.png";

const pageContent = {
  "search-engine-optimization": {
    hero: {
      heading: "Boost Your Visibility with Strategic, Data-Driven SEO",
      image: require("../../assets/boost/hero.png"),
      description:
        "At Innovative MOJO, we believe that ranking high in search results isn’t about shortcuts—it’s about authentic, sustainable growth. Our SEO experts blend deep keyword research, technical optimization, and compelling content strategies to elevate your brand’s presence on Google and other search engines. From on-page enhancements to authoritative link-building, we help ensure that your audience finds you first.", // image: require("../../../assets/boost/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/boost1.svg"),
        heading: "Long-Term Results",
        description: [
          "We build SEO strategies designed to deliver steady, cumulative gains rather than temporary spikes.",
        ],
      },
      {
        image: require("../../assets/boost/boost2.svg"),
        heading: "User-Centric Focus",
        description: [
          "Our optimizations prioritize user experience, ensuring your site is fast, mobile-friendly, and easy to navigate.",
        ],
      },
      {
        image: require("../../assets/boost/boost3.svg"),
        heading: "Transparent Reporting",
        description: [
          "Gain full visibility into your ranking progress, organic traffic growth, and keyword performance.",
        ],
      },
    ],
    process: {
      step1Heading: "Discovery & Audit",
      step1descreption:
        "Conduct a thorough technical audit and keyword research to identify opportunities.",
      step2Heading: "On-Page Optimization",
      step2descreption:
        "Refine site structure, meta tags, headlines, and content for maximum impact.",
      step3Heading: "Content & Link Building",
      step3descreption:
        "Craft high-quality content and build relationships that earn authoritative backlinks.",
      step4Heading: "Performance Tracking",
      step4descreption:
        "Regularly measure rankings, traffic, and conversions to refine strategies over time.",
    },
    why: {
      heading: "Why Innovative MOJO for SEO?",
      image: require("../../assets/boost/right.png"),
      listItems: [
        "A team of seasoned SEO specialists with a track record of growing organic traffic.",
        "Tailored approaches for your industry and audience.",
        "Ongoing support to adapt to search algorithm changes and market trends.",
      ],
    },
  },
  "social-media-management": {
    hero: {
      heading: "Elevate Your Brand Voice Across Every Social Channel",
      description:
        "Social media is your brand’s frontline connection with your audience—and Innovative MOJO helps you make it count. We develop and execute tailored social strategies that captivate followers, spark conversations, and drive measurable results. Whether you need to build brand awareness, increase engagement, or convert followers into customers, we’ve got you covered.",
      image: require("../../assets/boost/Social-media-manag/hero.png"),
    },

    cards: [
      {
        image: require("../../assets/boost/Social-media-manag/platform.svg"),
        heading: "Platform Expertise",
        description: [
          "From Instagram and LinkedIn to TikTok and Facebook, we know how to optimize content and outreach for each unique channel.",
        ],
      },
      {
        image: require("../../assets/boost/Social-media-manag/engaging.svg"),
        heading: "Engaging Content",
        description: [
          "Our creative team crafts scroll-stopping posts, videos, and stories to keep your audience intrigued.",
        ],
      },
      {
        image: require("../../assets/boost/Social-media-manag/data-driven.svg"),
        heading: "Data-Driven Insights",
        description: [
          "We use analytics to refine your strategy, ensuring that every post and campaign hits its target goals. ",
        ],
      },
    ],
    process: {
      step1Heading: "Brand & Audience Audit",
      step1descreption:
        "Understand your brand’s persona and the platforms most relevant to your audience.",
      step2Heading: "Content Calendar & Strategy",
      step2descreption:
        "Plan out a balanced mix of content types, messaging, and posting frequencies.",
      step3Heading: "Community Management",
      step3descreption:
        "Respond to comments, engage with followers, and nurture relationships to strengthen brand loyalty.",
      step4Heading: "Performance Review",
      step4descreption:
        "Track metrics like reach, engagement, and conversions, and adjust tactics for continuous improvement.",
    },
    why: {
      heading: "Why Innovative MOJO for Social Media?",
      image: require("../../assets/boost/Social-media-manag/why.png"),
      listItems: [
        "Creative minds backed by data-driven strategy.",
        "Proven engagement tactics to grow your follower base.",
        "End-to-end support, from content creation to community management.",
      ],
    },
  },
  "pay-per-click-advertising": {
    hero: {
      heading: "Turn Clicks into Conversions with Precision PPC Campaigns",
      description:
        "When done right, PPC isn’t just about buying clicks—it’s about buying opportunities. Our PPC specialists at Innovative MOJO design high-converting campaigns on Google Ads, Bing Ads, and social platforms to ensure every dollar you invest drives tangible returns. By blending compelling ad copy, refined audience targeting, and continuous optimization, we help you reach ready-to-convert prospects at the perfect moment.",
      image: require("../../assets/boost/PPC-advertising/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/PPC-advertising/small-target.svg"),
        heading: "Smart Targeting",
        description: ["Reach customers actively searching for what you offer."],
      },
      {
        image: require("../../assets/boost/PPC-advertising/budget-control.svg"),
        heading: "Budget Control",
        description: [
          "Set clear budgets, track spend, and see measurable ROI.",
        ],
      },
      {
        image: require("../../assets/boost/PPC-advertising/continious.svg"),
        heading: "Continuous Optimization",
        description: [
          "We never “set and forget.” Your campaigns are closely monitored and refined to improve results over time.",
        ],
      },
    ],
    process: {
      step1Heading: "Keyword & Audience Research",
      step1descreption:
        "Identify the best keywords, demographics, and interests to target.",
      step2Heading: "Ad Creation",
      step2descreption:
        "Develop compelling ad copy and creative assets that speak directly to user intent.",
      step3Heading: "Launch & Monitor",
      step3descreption:
        "Carefully implement campaigns and track performance metrics daily.",
      step4Heading: "Analysis & Refinement",
      step4descreption:
        "Use data-driven insights to adjust bids, targeting, and ad creative for ongoing improvement.",
    },
    why: {
      heading: "Why Innovative MOJO for PPC?",
      image: require("../../assets/boost/PPC-advertising/why.png"),
      listItems: [
        "Certified PPC professionals experienced with multiple ad platforms.",
        "Transparent reporting on campaign performance and costs.",
        "A relentless focus on conversion optimization.",
      ],
    },
  },
  "web-design-development": {
    hero: {
      heading: "Crafting Digital Experiences That Convert & Delight",
      description:
        "Your website is more than a digital storefront—it’s the heart of your brand’s online presence. Innovative MOJO’s web designers and developers create visually stunning, user-friendly, and high-performing websites that reflect your brand ethos and guide visitors seamlessly through their journey. Responsive, secure, and optimized for search, our sites are engineered to impress your audience and convert them into customers.",
      image: require("../../assets/boost/web-design/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/web-design/custom.svg"),
        heading: "Custom Designs",
        description: [
          "Beautifully tailored interfaces that embody your brand’s unique personality.",
        ],
      },
      {
        image: require("../../assets/boost/web-design/mobile.svg"),
        heading: "Mobile-First Approach",
        description: [
          "Seamless experiences on smartphones, tablets, and desktops.",
        ],
      },
      {
        image: require("../../assets/boost/web-design/seo.svg"),
        heading: "SEO-Friendly Code",
        description: [
          "We build with best practices to give you a strong foundation in search results.",
        ],
      },
    ],
    process: {
      step1Heading: "Discovery & Strategy",
      step1descreption:
        "Understand your goals, audience, and brand identity to inform design and UX.",
      step2Heading: "Wireframing & Design",
      step2descreption:
        "Translate insights into intuitive layouts, color palettes, and typography.",
      step3Heading: "Development & Testing",
      step3descreption:
        "Build, test, and refine the site for speed, security, and cross-browser compatibility.",
      step4Heading: "Launch & Ongoing Support",
      step4descreption:
        "Deploy the finished product and offer maintenance services to keep it running at peak performance.",
    },
    why: {
      heading: "Why Innovative MOJO for Web Design?",
      image: require("../../assets/boost/web-design/why.png"),
      listItems: [
        "Designers and developers with a keen eye for both aesthetics and functionality.",
        "Transparent project timelines and collaborative feedback cycles.",
        "Long-term support and iterative improvements as your business grows.",
      ],
    },
  },
  "content-marketing": {
    hero: {
      heading: "Inspire, Educate, and Convert with Compelling Content",
      description:
        "Great content doesn’t just fill space—it shapes opinions, builds trust, and compels action. Innovative MOJO’s content marketing services revolve around understanding your audience’s needs, then delivering valuable, relevant information that positions your brand as an industry authority. From blog posts and whitepapers to infographics and videos, we help you tell a story that resonates.",
      image: require("../../assets/boost/web-design/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/content-marketing/strategic.svg"),
        heading: "Strategic Storytelling",
        description: [
          "Content aligned with your brand voice and audience interests.",
        ],
      },
      {
        image: require("../../assets/boost/content-marketing/seo.svg"),
        heading: "SEO-Driven",
        description: [
          "Each piece is optimized to increase your organic visibility.",
        ],
      },
      {
        image: require("../../assets/boost/content-marketing/multi.svg"),
        heading: "Multi-Format Expertise",
        description: [
          "Written articles, video scripts, podcasts, social posts, and more.",
        ],
      },
    ],
    process: {
      step1Heading: "Content Audit & Strategy",
      step1descreption:
        "Identify content gaps and map out topics that match audience needs.",
      step2Heading: "Creation & Approval",
      step2descreption:
        "Produce high-quality, original content and refine it based on your feedback.",
      step3Heading: "Distribution & Promotion",
      step3descreption:
        "Share content across channels—social media, email newsletters, industry forums—to maximize reach.",
      step4Heading: "Performance Analysis",
      step4descreption:
        "Track metrics like engagement, shares, and conversions, and adjust future efforts accordingly.",
    },
    why: {
      heading: "Why Innovative MOJO for Content Marketing?",
      image: require("../../assets/boost/content-marketing/why.png"),
      listItems: [
        "Skilled storytellers who blend creativity with strategy.",
        "Strict adherence to brand guidelines and editorial standards.",
        "Continuous optimization based on performance metrics and audience feedback.",
      ],
    },
  },
  "define-your-voice": {
    hero: {
      heading: "Define Your Distinct Voice and Place in the Market",
      description:
        "Your brand is more than a logo—it’s the essence of what you represent, the promise you make to your customers. Innovative MOJO helps you define your brand’s personality, positioning, and messaging to stand out in a crowded marketplace. By clarifying your mission, values, and unique selling points, we ensure every customer touchpoint reflects your core identity.",
      image: require("../../assets/boost/web-design/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/define/market.svg"),
        heading: "Market Research",
        description: [
          "Data-driven insights into competitor landscapes and audience preferences.",
        ],
      },
      {
        image: require("../../assets/boost/define/message.svg"),
        heading: "Clear Messaging",
        description: [
          "Distill complex ideas into compelling brand narratives and value propositions.",
        ],
      },
      {
        image: require("../../assets/boost/define/exp.svg"),
        heading: "Consistent Experience",
        description: [
          "Align brand voice and visuals across all marketing channels.",
        ],
      },
    ],
    process: {
      step1Heading: "Discovery & Analysis",
      step1descreption:
        "Understand your industry, competitors, and audience to identify growth opportunities.",
      step2Heading: "Brand Identity Creation",
      step2descreption:
        "Develop logos, color palettes, tone of voice, and core messaging guidelines.",
      step3Heading: "Implementation Roadmap",
      step3descreption:
        "Integrate your brand identity into websites, social media, advertising, and collateral.",
      step4Heading: "Monitoring & Evolution",
      step4descreption:
        "Refine your strategy as market conditions change to ensure longevity and relevance.",
    },
    why: {
      heading: "Why Innovative MOJO for Brand Strategy?",
      image: require("../../assets/boost/define/why.png"),
      listItems: [
        "Experienced branding professionals with multidisciplinary backgrounds.",
        "Comprehensive approach that ties branding into measurable business objectives.",
        "Long-term partnership to keep your brand fresh, authentic, and resonant.",
      ],
    },
  },
  "video-production-and-marketing": {
    hero: {
      heading: "Bring Your Story to Life with Engaging, High-Impact Videos",
      description:
        "Video is today’s most powerful storytelling medium. At Innovative MOJO, we produce exceptional video content—from concept development and scriptwriting to filming, editing, and distribution—that captivates viewers and drives results. Whether it’s a brand anthem video, product demo, testimonial, or social snippet, we tailor our productions to resonate with your audience and amplify your message.", //   image: require("../../../assets/social-media-management/hero.png"),
      image: require("../../assets/boost/web-design/hero.png"),
    },
    cards: [
      {
        image: require("../../assets/boost/vieo-production/full.svg"),
        heading: "Full-Service Production",
        description: [
          "Creative ideation, professional filming, editing, motion graphics, and sound design.",
        ],
      },
      {
        image: require("../../assets/boost/vieo-production/stroytelling.svg"),
        heading: "On-Brand Storytelling",
        description: [
          "Videos aligned with your visual identity and core messaging.",
        ],
      },
      {
        image: require("../../assets/boost/vieo-production/results.svg"),
        heading: "Results-Oriented",
        description: [
          "Strategically optimized content to engage viewers and encourage conversions.",
        ],
      },
    ],
    process: {
      step1Heading: "Concept & Script",
      step1descreption:
        "Collaborate on storyboards, messaging, and narrative flow.",
      step2Heading: "Production",
      step2descreption:
        "Leverage professional crews, equipment, and techniques to capture high-quality footage.",
      step3Heading: "Post-Production & Editing",
      step3descreption:
        "Refine the footage with visual effects, color grading, music, and voiceovers.",
      step4Heading: "Distribution & Promotion",
      step4descreption:
        "Ensure your videos reach the right audience through strategic placement and analytics-driven optimizations.",
    },
    why: {
      heading: "Why Innovative MOJO for Video Production?",
      image: require("../../assets/boost/vieo-production/why.png"),
      listItems: [
        "Award-winning team experienced in various formats and industries.",
        "Smooth, transparent process from pre-production planning to final delivery.",
        "Video marketing strategies that boost engagement, brand recall, and sales.",
      ],
    },
  },
};

export default pageContent;
