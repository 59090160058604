import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from 'react-player';

import intel from "../../../assets/newpage/thumbnails/midnewThumbnail 2 (2).png";
import intellogo from "../../../assets/newpage/thumbnails/intel.png";

function Video2() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  return (
    <>
      <button
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={handleShow}
      >
        <img className="digital-product mt-2" src={intel} alt="" />
        <img className="digital-logo " src={intellogo} alt="" />
      </button>

      <Modal
        backdrop="static"
        id="iframeModal"
        show={show}
        onHide={handleClose}
        size="xl"
        centered
      >
        {/* <h1>SCHEDULE YOUR CALL BELOW</h1> */}
        <Modal.Body>
   
        {/* <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' /> */}
         
        <ReactPlayer
        url="/intalvideo.mp4"
        controls
        width="100%"
        height="auto"
      />
    

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btncontact"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Video2;
