import React from "react";
import "./hero.css";

import arrow from "../../../assets/newpage/arrow.svg";
import Navbar from "../../../components/navbar/navbarcopy";
import logo from "../../../assets/home/logo.svg";

const Hero = () => {
  return (
    <>
      <div className="hero-parent">
        <Navbar />

        <div className="  main ">
          <video autoPlay loop muted playsInline className="background-video">
            <source src="/hero.mp4" type="video/mp4" />
            {/* Add additional source tags for other video formats if needed */}
            Your browser does not support the video tag.
          </video>

          <div className="overlayhero"></div>
          <div className="gradient-bottom-hero"></div>

          <div
            className=" container contentHeroNew"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="newpage-mob-logo-header">
              <img className="newpage-mob-logo-header-img" src={logo} alt="" />
            </div>

            <h1 className="heading-hero text-center ">
              Welcome to Innovative MOJO
            </h1>
            <h1 className="sub-heading-hero text-center ">
              AI-Driven Marketing for the Future
              {/* <span className="mob-hidden">—</span>
              <br className="mob-nextline" />
              The Perfect Collision for Success */}
            </h1>

            <p className="text-center d-flex justify-content-center para my-4 ">
              <span className="hero-para">
                At Innovative MOJO, we harness the power of AI-driven marketing,
                SEO, and brand storytelling to create dynamic, data-driven
                strategies that elevate your brand. Our cutting-edge AI suite
                optimizes every aspect of your marketing— from SEO and content
                creation to video production, automation, and analytics—
                ensuring maximum impact and efficiency.
              </span>
            </p>
            <p className="text-center d-flex justify-content-center para my-4">
              <span className="hero-para">
                Just like the perfect fusion of art and intelligence, our
                approach blends AI precision with human creativity, driving
                smarter campaigns, deeper engagement, and faster growth.{" "}
                <p className="home-hero-banner-ready-span bouncing-effect">
                  Ready to revolutionize your brand with AI?
                </p>
              </span>
            </p>

            <div className="d-flex justify-content-center gap-3 mr-3">
              <a href="/our-work">
                <button className="btncontact">See Our Work</button>
              </a>

              <a href="/booking">
                <button className="btncontact">
                  Lets Discuss
                  <img className="ml-2 button-arrow" src={arrow} alt="" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
