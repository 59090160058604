import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initial, portfolios } from "../../utils/portfolio";

import NewHeights from "../../components/new-heights/new-heights.component";
import HomeFooter from "../../components/home-footer/home-footer.component";
import SmallBanner from "../../components/small-banner/small-banner.component";
import PrimaryButton from "../../components/button-primary/button-primary.component";
import MobileFeatures from "../../components/mobile-features/mobile-features.component";

import "./mobile-dev.styles.scss";
export default function MobileDevelopment() {
  const navigate = useNavigate();
  const params = useParams("id").id;
  const [portfolio, setPortfolio] = useState(initial);

  useEffect(() => {
    if (params) {
      setPortfolio(portfolios[params - 1]);
    }
  }, [portfolio, params]);

  const myRef = useRef(null);

  return (
    <div className="mobile-dev-page">
      <SmallBanner title={portfolio.name} heading={portfolio.description} />
      <div className="mobile-development-component ">
        <NewHeights process={portfolio.process} image={portfolio.images[0]} />
        <div ref={myRef}>
          <MobileFeatures features={portfolio.processes} />
        </div>

        <div className="what-we-do-container">
          <div className="what-we-do col-10  mx-auto">
            <div className="what-we-do-header">
              <h2>Results</h2>
              <span className="subtext">{portfolio.result}</span>
              <div className="capabity-container">
                {portfolio.results.map((result, index) => (
                  <div key={index} className="capability">
                    {result}
                  </div>
                ))}
              </div>
            </div>
            <div className="circle-container">
              <div className="outer-circle">
                <div className="inner-circle">
                  <img src={portfolio.whatwedo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="support">
          <div className="container support-container ">
            <div className="support-info">
              <h2>Testimonial</h2>
              <div className="divider"></div>
              <span className="subtext">{portfolio.testimonial.text}</span>
              <PrimaryButton onClick={() => navigate("/booking")}>
              Lets Discuss
              </PrimaryButton>
            
            </div>
            <img className="support-img" src={portfolio.images[2]} alt="" />
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}
