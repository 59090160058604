import React from "react";

import "./included.css";

import seo from "../../../assets/mojoFusion/seo.svg";
import videography from "../../../assets/mojoFusion/videography.svg";
import bonusSvg from "../../../assets/mojoFusion/bonus.svg";

const FusionIncluded = () => {

  return (
    <div className="included-main-div">
      <h1 className="digitalheading text-center">
        What’s <span className="digitaltitle">Included</span>
      </h1>
      <div className="included-flex-div Fusion-included-flex-div">
        {/* vediography card */}
        <div className="included-cards-parent Fusion-included-cards-parent">
          <div className="included-cards-child">
          <div className="included-cards-image-div">
            <img
              src={videography}
              alt="image"
              className="included-cards__image"
            />
            </div>
            <h2 className="included-cards-heading">MOJO Minute Videography</h2>

            <ul className="included-cards-list">
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Consultation & Concept Development:
                </span>{" "}
                Begin with a personalized consultation to understand your
                brand's goals and audience, crafting a compelling 45 to
                60-second commercial.
              </li>

              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Scriptwriting & Storyboarding:
                </span>{" "}
                Our creative team develops a concise and impactful script, along
                with a detailed storyboard to ensure your vision comes to life.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Vision Board Presentation:
                </span>{" "}
                Get a visual preview of the video's style and aesthetic,
                ensuring every detail aligns with your brand’s personality.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Filming & Production:
                </span>{" "}
                Our professional videographers use state-of-the-art equipment to
                produce a high-quality video that tells your brand's story
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Post-Production & Editing:
                </span>{" "}
                Our editors enhance the footage with music, graphics, and
                special effects, creating a polished final product.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Five 15-Second Reels:
                </span>{" "}
                Alongside the main commercial, receive five short reels
                optimized for social media to increase engagement and reach
              </li>
            </ul>
          </div>
        </div>
        {/* vediography card */}

        {/* Seo Mastery card */}
        <div className="included-cards-parent Fusion-included-cards-parent">
          <div className="included-cards-child">
            <div className="included-cards-image-div">
            <img src={seo} alt="image" className="included-cards__image" />
            </div>

            <h2 className="included-cards-heading">SEO Mastery</h2>

            <ul className="included-cards-list">
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  In-Depth SEO Audit:
                </span>{" "}
                A comprehensive analysis of your current SEO performance,
                identifying areas for improvement and opportunities for growth.
              </li>

              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Keyword Research & Optimization:
                </span>{" "}
                In-depth keyword research and on-page optimization to ensure
                your content targets high-converting search terms.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Technical SEO Enhancements:
                </span>{" "}
                Optimize your website’s technical aspects, including site speed
                and mobile responsiveness, for improved search engine
                performance
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  High-Quality Content Creation:
                </span>{" "}
                Creation of SEO-friendly content that resonates with your
                audience and boosts your online visibility.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Link Building & Off-Page SEO:
                </span>{" "}
                Strategic link-building campaigns to improve your website’s
                domain authority and search engine rankings.
              </li>
              <li className="included-cards-list-item Fusion-included-cards-list-item">
                <span className="included-cards-list-span">
                  Analytics & Reporting:
                </span>{" "}
                Receive monthly performance reports with insights and
                recommendations to refine your SEO strategy for maximum results.
              </li>
            </ul>
          </div>
        </div>
        {/* Seo Mastery card */}
      </div>

      {/*Bonus  */}
      <div className="fusion-bonus">
        <div className="additional-feature-content">
        <div className="included-cards-image-div">
          <img src={bonusSvg} alt="additional feature" />
          </div>
          <h2 className="Bonus-free-heading">
            <span className="included-cards-list-span">Bonus:</span> Free
            Professional Website
          </h2>
          <ul>
            <li className="additional-feature-li bonus-li">
              <span className="included-cards-list-span">Custom Design:</span> {" "}
               Get a professionally designed website tailored to
              your brand, providing a seamless user experience and showcasing
              your brand story.
            </li>
            <li className="additional-feature-li bonus-li">
            <span className="included-cards-list-span"> SEO-Optimized: </span> {" "}
              Your new website is built with SEO best practices
              in mind, ensuring it ranks well on search engines right from the
              start.
            </li>
            <li className="additional-feature-li bonus-li">
            <span className="included-cards-list-span"> 
              Responsive & User-Friendly:
              </span> {" "}
               Designed to look great and function
              flawlessly on all devices, offering a top-notch experience for
              every visitor.
            </li>
            <li className="additional-feature-li bonus-li">
            <span className="included-cards-list-span"> 
              Integrated Branding:
              </span>{" "}
               The website seamlessly integrates with your
              video content and SEO strategy, delivering a cohesive and powerful
              brand presence.
            </li>
          </ul>
        </div>
      </div>
      {/* Bonus */}
    </div>
  );
};

export default FusionIncluded;
