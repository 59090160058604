import React, { useState, useEffect } from "react";

import "./home.styles.scss";

import AOS from "aos";
import "aos/dist/aos.css";
import Hero from "../../components/newpage/HeroSection/Hero";
import BackgroundEffect from "../../components/background-effect/background-effect.component";
import HomeWrapper from "../../components/home-wrapper/home-wrapper.component";
import Transformation from "../../components/newpage/Transformation/Transformation";
import Seo from "../../components/newpage/Seo/Seo";
import SeoSlider from "../../components/newpage/Seo/SeoSlider";
import Ourteam from "../../components/newpage/Ourteam/Ourteam";
import Director from "../../components/newpage/Director/Director";
import HeadingSlider from "../../components/newpage/Slider/HeadingSlider";
import Slider from "../../components/newpage/Slider/Slider";
import DigitalDirector from "../../components/newpage/DigitalDirector/DigitalDirector";
import Cards from "../../components/newpage/Cards/Cards";
import CardsSlider from "../../components/newpage/Cards/CardsSlider";
import Unparalleled from "../../components/newpage/Unparalleled/Unparalleled";
import Footer from "../../components/home-footer/home-footer.component";
import SeoNewDesign from "../../components/newpage/SeoNewDesign";
import { Helmet } from "react-helmet";
import TestimonialSlider from "../../components/newpage/TestimonialSlider";
// import Animation from "../components/Animation";
// import SeoAnimation from "../components/Seo/SeoAnimation";
const Newpage = () => {
  const [loaded, setLoaded] = useState(true);
  const [text, setText] = useState(-1);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Only animate once
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setText(0);
    }, 180);
    setTimeout(() => {
      const interval = setInterval(() => {
        setText((prevText) => {
          return !prevText || prevText === 1 ? 1 : (prevText + 1) % 3;
        });
      }, 600);

      setTimeout(() => {
        clearInterval(interval);

        setText(5);
      }, 2000);
      setTimeout(() => {
        setLoaded(true);
      }, 3200);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("animationPlayed")) {
      setLoaded(false);
      sessionStorage.setItem("animationPlayed", "true");
    }
  }, []);

  return (
    <div className="bgcolor home-container-new">
      {/* <Helmet>
        <title>
          SEO Optimization Company|Mobile App Development Company|Innovative
          Mojo
        </title>
        <meta
          name="description"
          content="Elevate your digital presence with Innovative Mojo, a leading SEO Optimization Company and Mobile App Development Company. Contact us today for transformative digital strategies."
        />
        <meta
          name="keywords"
          content="React Js Developer, React Js Web Development, React Js Website Development"
        />
        
      </Helmet> */}
      <Helmet>
        <title>
          Innovative Mojo | Digital Marketing Company - Elevate Your Brand
          Today!
        </title>
        <meta
          name="description"
          content="Elevate your brand with Innovative Mojo, a leading digital marketing company offering expert solutions in SEO, branding, video, and more. Let us take your business to the next level!"
        />
      </Helmet>
      {/* {loaded ? ( */}
      <div className="fade-in">
        <Hero />
        <DigitalDirector />
        <Transformation />
        <div className="seo-desktop seo-desktop-main">
          <SeoNewDesign />
        </div>
        <div className="mob-seo-slider">
          <SeoSlider />
        </div>
        <Ourteam />
        {/* <Director /> */}
        <HeadingSlider />
        <Slider />
        <Cards />
        {/* <CardsSlider /> */}
        {/* <TestimonialSlider/> */}
        <Unparalleled />
        <Footer />
      </div>
      {/* ) : (
        <div className="loading-screen">
          <BackgroundEffect />
          <div className="overlay square">
            <div className={`small-square ${text === 5 ? "animated" : ""}`}>
              {text === 0 && <span className="text1">FIND</span>}
              {text === 1 && <span className="text2">YOUR</span>}
              {text === 5 && <span className="text4">MOJO</span>}
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Newpage;
