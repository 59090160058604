import React from "react";

import "./Hero.css";

import Navbarr from "../../navbar/navbar.component";

const RevenueHero = () => {
  return (
    <>
      <Navbarr />
      <div className="seo-parent revenue-parent">
        <div className="seo-parent-child revenue-hero-div">
          <div className="revenue-content-div">
            <div className="revenue-left-side">
              <h1 className="heading-Transformation text-center">
                Unlock the secrets & strategies to supercharge{" "}
                <span className="goldcolor"> SEO and Marketing</span> success.{" "}
              </h1>
              <h3 className="revenue-subheading text-center">
                Get Insider Tips From AI-driven Playbooks Used By Top Brands
                Worldwide.
              </h3>
              <p className="revenue-para text-center">
                Looking to optimize your SEO or boost your marketing results?
                Learn how industry experts leverage AI to achieve breakthrough
                success and stay ahead of the competition. Our podcast brings
                you innovative strategies and actionable insights every week. in
                revenue 
              </p>
            </div>
            {/* <div className="revenue-right-side">
              <img className="revenue-svg" src={revenuesvg} alt="hero" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueHero;
